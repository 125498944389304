import React, { useEffect } from 'react';

import {
  Container,
  Side,
  Main,
  Header,
  Body,
  Footer,
  Logo,
  Text,
  Title,
  Content,
} from './styles';

import illustration from '../../assets/terms-privacy.svg';
import logo from '../../assets/easycontent-logo@2x.png';

function Terms() {
  useEffect(() => {
    document.title = 'EasyContent - Termos de Uso e Política de Privacidade';
  }, []);

  return (
    <Container>
      <Side>
        <Header>
          <Logo>
            <img src={logo} alt="EasyContent" />
          </Logo>
        </Header>
        <Body>
          <Text>
            <h1>Pesquise, conheça e repita</h1>
            <p>Enriqueça a sua leitura na internet a qualquer momento</p>
          </Text>
        </Body>
        <Footer>
          <img src={illustration} alt="ilustração" />
        </Footer>
      </Side>
      <Main>
        <Content>
          <Title>Termos de Uso e Política de Privacidade</Title>
          <p style={{ fontWeight: 'bold' }}>Bem-vindo à Easy Content.</p>
          <p>Agradecemos por utilizar nossos produtos e serviços!</p>
          <p>
            Os serviços que tratam o presente termo são fornecidos pela sociedade SimpleAds Tecnologia e Informação Digital Ltda., sito à Rua Pamplona, n° 518, 11º andar, Bela Vista, São Paulo/SP, CEP 01405-000, Brasil.
          </p>
          <p>
            Você poderá acessar a versão mais recente dos presentes Termos de
            Uso, a qualquer tempo, através do endereço eletrônico
            <a
              href="https://www.simpleads.com.br"
              target="_blank"
              rel="noreferrer"
            >
              www.simpleads.com.br
            </a>
            .
          </p>
          <p>
            Ao utilizar os nossos serviços, você concorda e se sujeita aos
            termos abaixo discriminados, sendo imprescindível, portanto, que os
            leia com bastante atenção.
          </p>
          <span>1. Apresentação</span>
          <p>
            O serviço Easy Content, apelidado de “caixa” ou “caixinha”, tem como
            principal objetivo ajudá-lo a acessar os mais variados
            conhecimentos, conectando-o a inúmeros autores e criadores de
            conteúdo, tudo sem que seja necessário que você abra uma nova
            janela, inicie uma nova pesquisa ou desvie sua atenção do texto que
            está lendo.
          </p>
          <p>
            Nosso intuito é tornar possível o acesso a informações da mais alta
            qualidade, que sejam realmente capazes de auxiliá-lo em sua leitura,
            agregando valor e enriquecendo sua experiência, a cada nova
            “caixinha” aberta.
          </p>
          <p>
            No mesmo propósito, queremos oferecer a possibilidade de que você
            interaja com diversas marcas e produtos relevantes, através de
            anúncios, promoções, publicidades e/ou propagandas que poderão estar
            inseridos nas “caixinhas”, e isso, se você decidir ativar esse
            recurso. A decisão é sua.
          </p>
          <p>
            Contudo, para o melhor aproveitamento do nosso Serviço, é necessário
            que você siga rigorosamente as políticas e termos disponibilizados.
          </p>
          <span>2. Regras de utiliazação do Serviço</span>
          <p>
            Não faça uso indevido do nosso Serviço. Não interfira com a sua
            forma de disponibilização com as suas funcionalidades, nem tente
            acessá-lo por meio diverso da interface e das instruções que
            fornecemos. Não utilize versões modificadas do nosso Software
            inclusive, sem limitação, com a finalidade de obter acesso não
            autorizado ao Serviço.
          </p>
          <p>
            Você deve usar nosso Serviço somente segundo as orientações
            previstas nesses termos e demais políticas disponibilizadas pela
            SimpleAds, observando sempre o quanto disposto na Lei. O uso
            indevido do Serviço, a eventual necessidade de que se abra
            investigação para apurar má conduta, bem como os demais casos de
            violação destes termos, poderão ensejar o bloqueio do usuário, a
            suspensão ou a cessação definitiva da prestação do Serviço.
          </p>
          <p>
            Para alcançar o maior desempenho possível, podemos enviar-lhe
            anúncios de novos produtos, mensagens administrativas e/ou outras
            informações. Tais comunicações são necessárias para a manutenção e
            remuneração da prestação do serviço. Caso você utilize qualquer
            método para evitar a exibição dessas comunicações, você será avisado
            e as “caixinhas” deixarão de ser acessíveis em sua navegação.
          </p>
          <p>
            As funcionalidades do nosso Serviço poderão estar disponíveis em
            dispositivos móveis. O usuário não deve utilizar tais Serviços de
            forma que o distraia ou o impeça de cumprir leis de trânsito ou de
            segurança.
          </p>
          <span>3. Propriedade Intelectual</span>
          <p>
            Você reconhece e concorda que o Serviço, bem como qualquer software
            necessário utilizado em conexão com o Serviço, contém informações
            confidenciais de acordo com a Lei de Propriedade Industrial (Lei n°
            9.279/1996), Lei da Propriedade Intelectual de Programa de
            Computador (Lei n° 9.609/1998), Lei de Direitos Autorais e normas
            correlatas, do Brasil e do exterior.
          </p>
          <p>
            Estes termos ou a utilização do nosso Serviço não lhe confere
            qualquer direito de propriedade intelectual, qualquer titularidade
            ou participação sobre o Serviço ou sobre o conteúdo acessado, assim
            como não lhe permite remover, ocultar ou alterar quaisquer avisos
            legais exibidos dentro ou junto das “caixinhas”.
          </p>
          <p>
            Nesse sentido, você concorda em não reproduzir, modificar, alugar,
            arrendar, vender, negociar, distribuir, transmitir, difundir,
            executar publicamente, criar obras derivadas, explorar ou utilizar
            de qualquer outra maneira, para fins comerciais, as marcas,
            logotipos, sinais distintivos ou qualquer outro tipo de propriedade
            intelectual da SimpleAds, sem autorização prévia, expressa e por
            escrito.
          </p>
          <span>4. Conteúdo disponibilizado no Easy Content</span>
          <p>
            O conteúdo disponibilizado em nossas “caixinhas” é proveniente de
            diferentes origens e autores, o que traz maior diversidade e
            qualidade para o usuário. Sempre respeitamos as regras de uso e as
            políticas de privacidade dessas origens e disponibilizamos, em todas
            as “caixinhas”, o endereço eletrônico do local onde a informação foi
            originalmente divulgada por seu titular.
          </p>
          <p>
            Não modificamos ou alteramos qualquer conteúdo, mas poderemos,
            eventualmente, disponibilizá-lo de forma parcial, sendo sempre
            possível acessá-lo integralmente pelo endereço eletrônico indicado.
          </p>
          <p>
            A SIMPLEADS NÃO CONTROLA A PRODUÇÃO DO CONTÉUDO DISPONIBILIZADO
            ATRAVÉS DO SERVIÇO. NÃO REVISAMOS, NECESSARIAMENTE, O CONTEÚDO
            DISPONIBILIZADO EM NOSSAS “CAIXINHAS”. NOSSO SERVIÇO TEM POR
            OBJETIVO, TÃO SOMENTE, APROXIMAR E POSSIBILITAR A CONEXÃO DOS
            USUÁRIOS COM OS REAIS CRIADORES E PRODUTORES DE CONTEÚDO.
          </p>
          <p>
            COMO FORMA DE GARANTIR O CUMPRIMENTO DAS NOSSAS POLÍTICAS E DA
            LEGISLAÇÃO APLICÁVEL, PODEREMOS, A NOSSO CRITÉRIO, REVISAR OS
            CONTEÚDOS VINCULADOS ÀS NOSSAS “CAIXINHAS”, ASSIM COMO REMOVÊ- LOS,
            CASO ENTENDAMOS QUE NÃO REFLETEM NOSSOS VALORES E NÃO CONDIZEM COM
            OS TERMOS AQUI AJUSTADOS. CONTUDO, TAL AÇÃO DEVE SER INTERPRETADA
            COMO EXERCÍCIO DE MERA PRERROGATIVA, OU SEJA, NÃO TEMOS QUALQUER
            RESPONSABILIDADE OU OBRIGAÇÃO DE FISCALIZAR O CONTEÚDO
            DISPONIBILIZADO E/OU REMOVÊ-LO.
          </p>
          <p>
            Por ser de titularidade de terceiros, o conteúdo é de única e
            exclusiva responsabilidade de seus criadores. Isso significa que
            cada titular de conteúdo, e nunca a SimpleAds, é integralmente
            responsável por todo e qualquer conteúdo transmitido através do Easy
            Content, sendo devidamente indicada a sua titularidade.
          </p>
          <p>
            Você não pode utilizá-lo sem a obtenção de autorização específica do
            autor ou titular, a não ser que o faça por algum meio permitido pela
            Lei de Direitos Autorais (Lei n° 9.610/1998) ou pelas demais
            legislações ou regulamentos aplicáveis.
          </p>
          <span>5. Proteção à privacidade e Direitos Autorais</span>
          <p>
            Em nossas Políticas de Privacidade, que podem ser encontradas no
            endereço eletrônico (link), você encontrará todas as informações a
            respeito de como lidamos com os seus dados pessoais e protegemos a
            sua privacidade. Ao usar o Serviço, você concorda com a nossa
            Política de Privacidade e está ciente de que a SimpleAds poderá usar
            seus dados nos termos da referida política.
          </p>
          <p>
            A SimpleAds respeita a propriedade intelectual de terceiros, e
            esperamos que você faça o mesmo. Em circunstâncias especiais e a
            nosso critério, podemos desativar, modificar, excluir e/ou tomar
            outras medidas apropriadas em relação a usuários ou terceiros que
            possam ter violado direitos autorais ou propriedade intelectual.
            Caso você encontre algum conteúdo vinculado às nossas “caixinhas”
            que você tenha divulgado, certifique-se de que possui a titularidade
            ou as autorizações necessárias para tanto, visto que será o único
            responsável por eventuais danos causados ao real titular do
            conteúdo.
          </p>
          <p>
            Nossos sistemas automatizados exibem apenas conteúdos que permitem a
            sua divulgação. Assim, caso algum conteúdo vinculado seja de sua
            titularidade e esteja indevidamente disponibilizado para divulgação
            e compartilhamento, eventual responsabilidade deverá ser apurada,
            exclusivamente, em face da pessoa ou entidade que os divulgou sem a
            sua autorização.
          </p>
          <p>
            Nós disponibilizamos uma ferramenta de comunicação para que você nos
            comunique sobre qualquer irregularidade ou violação de direitos
            autorais ou de propriedade intelectual seus ou de terceiros que
            cheguem ao seu conhecimento. Se quiser fazê-lo agora,
            <a
              href="https://www.simpleads.com.br"
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: '4px' }}
            >
              clique aqui
            </a>
            e será direcionado ao formulário
          </p>
          <span>6. Garantia</span>
          <p>
            A SimpleAds, seus desenvolvedores e distribuidores não oferecem
            quaisquer garantias, que não as previstas na Lei ou nos presentes
            Termos de Uso. Fornecemos nosso Serviço “na forma em que é
            disponibilizado”, com todas as características que lhe sejam, mesmo
            que momentaneamente, intrínsecas.
          </p>
          <p>
            A utilização do Serviço e a obtenção dos meios necessários para
            tanto é de sua exclusiva responsabilidade, não havendo obrigação por
            parte da SimpleAds de lhe prestar qualquer assistência ou suporte
            técnico.
          </p>
          <p>
            Conforme informado no item 4, a SimpleAds não cria ou controla o
            conteúdo vinculado no Easy Content. Tal conteúdo é proveniente de
            terceiros e será disponibilizado nas “caixinhas” com a devida
            referência (link para o site de origem).
          </p>
          <p>
            Como consequência disto, a SimpleAds não garante a correção,
            confiabilidade e assertividade do conteúdo, bem como que o mesmo
            atenderá integralmente as suas necessidades, apesar de ser este o
            objetivo.
          </p>
          <p>
            Do mesmo modo, não garantimos que a publicidade ou propaganda
            veiculadas cumprirão satisfatoriamente os requisitos previstos pela
            legislação aplicável, dentre os quais os deveres de transparência e
            informação.
          </p>
          <span>7. Responsabilidade</span>
          <p>
            Com exceção dos casos previstos na Lei, a SimpleAds, seus
            desenvolvedores e distribuidores, não serão responsáveis por
            eventuais danos financeiros ou morais, dentre os quais incluem-se,
            mas não se limitam, os lucros cessantes, perda financeira de
            qualquer tipo e outras perdas decorrentes do uso ou da incapacidade
            de utilização do serviço, da prática de atos ilícitos ou não
            permitidos do próprio usuário ou de terceiros ou decorrentes de
            fatos ocorridos por caso fortuito ou força maior.
          </p>
          <p>
            Em nenhuma hipótese a SimpleAds será responsável pelo conteúdo de
            terceiros disponibilizado no Easy Content, inclusive, mas não se
            limitando, por qualquer erro ou omissão de informações em tal
            conteúdo ou por qualquer perda ou dano de qualquer espécie
            decorrente de conteúdo exibido, enviado, transmitido ou de qualquer
            forma disponibilizado por meio do Serviço. Você concorda que deverá
            avaliar previamente e assumir todos os riscos que possam decorrer da
            utilização do conteúdo disponibilizado, inclusive, por confiar que
            qualquer conteúdo exibido é correto, completo ou útil.
          </p>
          <p>
            Em todo caso, você declara estar ciente de que nas hipóteses de
            responsabilidade reconhecida pela Lei ou pelos presentes termos,
            decorrentes de reclamações de qualquer natureza, incluindo garantias
            implícitas, o valor a ser pago pela SimpleAds, seus desenvolvedores
            e distribuidores terá como limite o valor pago por você à SimpleAds
            para a utilização do Serviço.
          </p>
          <span>8. Negociações com os anunciantes</span>
          <p>
            A correspondência, negociação, visualização, clique ou participação
            que fizer em anúncios ou promoções de anunciantes, encontradas no ou
            divulgadas através do Serviço, incluindo pagamento ou entrega de
            bens e serviços, condições, garantias e apresentações associadas a
            tal negociação, ocorrem exclusivamente entre você e o anunciante.
          </p>
          <p>
            Nesse sentido, você reconhece que o conteúdo e as ofertas em
            qualquer propaganda vinculada nas “caixinhas” são de
            responsabilidade do respectivo anunciante e que a SimpleAds é
            somente um veículo de divulgação, sem condições de interferir nas
            atividades comerciais de tais anunciantes. Como consequência, você
            concorda que a SimpleAds não é responsável por qualquer perda ou
            dano decorrentes de tal negociação e/ou resultante da presença do
            anunciante no Serviço.
          </p>
          <span>9. links</span>
          <p>
            O Serviço ou terceiros (ex. anunciantes) poderão fornecer “links”
            para outros sites da World Wide Web ou outros recursos. Como a
            SimpleAds não tem controle sobre tais sites ou recursos externos,
            você reconhece e concorda que a SimpleAds não é responsável pela
            disponibilidade dos mesmos e não endossa ou se responsabiliza por
            qualquer conteúdo, propaganda, produtos, serviços ou outros
            materiais contidos ou disponibilizados através de tais sites ou
            recursos.
          </p>
          <p>
            Você expressamente reconhece que a SimpleAds não será responsável,
            direta ou indiretamente, por quaisquer perdas ou danos que sejam
            efetiva ou alegadamente causados pela confiança depositada no
            conteúdo, bens e serviços disponibilizados em tais sites.
          </p>
          <span>10. Modificações dos Termos de Uso e cancelamento</span>
          <p>
            Estamos em constante evolução, sempre visando alcançar a excelência
            na prestação dos nossos serviços. Para tanto, reservamo-nos o
            direito de modificar, suspender ou encerrar o serviço prestado, bem
            como adicionar ou excluir fontes de conteúdo e/ou patrocinadores, a
            qualquer tempo, no todo ou em parte e sem necessidade de prévio
            consentimento.
          </p>
          <p>
            Além disto, você pode deixar de usar nosso Serviço a qualquer
            momento, apesar de que ficaremos chateados ao te ver ir embora. A
            SimpleAds também poderá deixar de prestar o Serviço a você, bem como
            incluir ou criar novos limites a nosso Serviço, a qualquer tempo.
          </p>
          <span>11. Disposições gerais</span>
          <p>
            Você declara ter capacidade legal para aceitar e se obrigar aos
            presentes Termos de Uso ou estar devidamente representado ou
            assistido para tal fim.
          </p>
          <p>
            Estes termos representam um acordo que regerá toda a relação
            fornecedor-usuário entre você e a SimpleAds. Poderão ser
            apresentados adicionalmente a este documento termos específicos, que
            não substituirão as disposições aqui expostas, mas apenas as
            complementará, portanto, é necessário que você sempre esteja atento
            a quaisquer modificações realizadas neste documento, as quais serão
            devidamente informadas a você.
          </p>
          <p>
            Havendo conflito entre o aqui previsto e o constante dos termos
            adicionais, prevalecerão os termos adicionais, assim como as
            disposições do presente instrumento que não se revelem conflitantes.
          </p>
          <p>
            Caso você não concorde com eventuais modificações realizadas nestes
            termos, assim como com o teor de eventuais termos adicionais
            futuramente elaborados, deve descontinuar a utilização do serviço
            Easy Content.
          </p>
          <p>
            O não exercício de qualquer direito de titularidade da SimpleAds não
            implicará na renúncia do direito, ou seja, caso você descumpra estes
            Termos de Uso e/ou demais instrumentos a ele vinculados, mesmo que
            não sejam tomadas as medidas cabíveis imediatamente, a SimpleAds
            permanecerá com o direito de tomar providências em momento futuro.
          </p>
          <p>
            A nulidade ou inaplicabilidade de qualquer previsão deste Termo, não
            afetará as demais previsões, que permanecerão vigentes e exigíveis
            em sua integralidade.
          </p>
          <p style={{ marginBottom: '30px' }}>
            Os Termos de Uso e a relação estabelecida entre você e a SimpleAds,
            especificamente quanto à utilização do serviço Easy Content, estão
            sujeitos à legislação e às autoridades brasileiras. Você e a
            SimpleAds concordam em submeter quaisquer questões sobre o Serviço
            ou sobre estes termos aos tribunais localizados no Brasil,
            especificamente ao da comarca da sede da empresa, qual seja, São
            Paulo/SP.
          </p>
        </Content>
      </Main>
    </Container>
  );
}

export default Terms;

import React, { useEffect } from 'react';
import Lottie from 'react-lottie';

import animationData from '../../assets/notfound.json';

import { Container } from './styles';

function NotFound() {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    document.title = 'EasyContent - Nada Encontrado';
  }, []);

  return (
    <Container>
      <Lottie options={defaultOptions} height={400} width={400} />;
    </Container>
  );
}

export default NotFound;

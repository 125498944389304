import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';

const gradient = keyframes`
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  border: 40px;
  display: flex;
  height: 100%;
  border-radius: 40px;
  display: flex;
  align-items: center;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;

export const Side = styled.div`
  height: 90%;
  width: 40%;
  background: linear-gradient(45deg, #0093eb, #4e7df0, #7059d6, #fcaa06);
  background-size: 400% 400%;
  animation: ${gradient} 15s ease infinite;
  margin-left: 20px;
  margin-right: -40px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.075) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #fff;

  img {
    width: 100%;
    padding: 20px;
  }

  div {
    width: calc(100% - 20px);
  }

  ${media.lessThan('medium')`
    width: 100%;
    margin: 0;
    border-radius: 0;
    height: 100px;

    div {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      & + div {
        display: none;
      }
    }


    img {
      display: none;
    }
  `}
`;

export const Main = styled.div`
  height: 90%;
  width: 60%;
  background: #fff;
  border-radius: 40px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.075) !important;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow-y: hidden;
  overflow-x: hidden;

  ${media.lessThan('medium')`
    width: 100%;
    margin: 0;
    border-radius: 0;
    /* height: auto; */
  `}

  form {
    width: 100%;
    text-align: center;
  }
`;

export const Header = styled.div`
  height: 100px;
  width: 100%;
  color: #fff;
  padding: 20px;
  z-index: 2;
`;

export const Body = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
`;

export const Content = styled.div`
  width: 100%;
  height: 94%;
  padding: 10px 40px;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }
  ::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  p {
    margin-top: 20px;
    font-size: 16px;
  }

  a {
    margin-left: 4px;
    color: #0093eb;
    cursor: pointer;
  }

  span {
    margin-top: 18px;
    font-size: 18px;
  }

  ${media.lessThan('medium')`
    padding: 10px 20px;
  `}
`;

export const Footer = styled.div`
  width: 100%;
  padding: 20px;
`;

export const Logo = styled.div`
  text-align: center;
  font-size: 40px;

  ${media.lessThan('medium')`
    width: 100%;
    margin: 0;
    border-radius: 0;
    height: 100px;
  `}
`;

export const Text = styled.div`
  p {
    margin-top: 20px;
    font-size: 16px;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`;

export const Title = styled.h1`
  color: #333;
  text-align: center;
  padding: 20px;
`;

export const Btn = styled.button`
  margin-top: 10px;
  margin-bottom: 10px;
  height: 44px;
  background: linear-gradient(45deg, #0093eb, #4e7df0, #7059d6, #fcaa06);
  background-size: 400% 400%;
  animation: ${gradient} 15s ease infinite;
  padding: 10px 20px;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;

  svg {
    margin-left: 4px;
  }
`;

import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  textarea {
    border: 0.5px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    height: 120px;
    font-size: 16px;
    padding: 10px 15px;
    color: #333;
    width: 80%;
    resize: none;
    font-family: 'Roboto', sans-serif;

    &::placeholder {
      color: #9999;
    }

    &:focus {
      border: 2px solid #2684ff;
    }

    ${media.lessThan('small')`
      width: 90%;
    `}
  }

  label {
    color: #999;
    width: 80%;
    text-align: left;
    margin-bottom: 2px;

    display: flex;
    align-items: center;

    svg {
      margin-right: 4px;
      margin-top: 1px;
      color: #999;
    }

    ${media.lessThan('small')`
      width: 90%;
    `}
  }

  span {
    text-align: left;
    width: 80%;
    font-size: 12px;
    color: #dc3545;
    margin-top: 4px;

    ${media.lessThan('small')`
      width: 90%;
    `}
  }
`;

import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import ReportProblem from '../pages/ReportProblem';
import ReportAbuse from '../pages/ReportAbuse';
import Terms from '../pages/Terms';

import NotFound from '../pages/NotFound';

export default function Routes() {
  return (
    <Switch>
      <Route path="/reportar-problema" component={ReportProblem} />
      <Route path="/reportar-abuso" component={ReportAbuse} />
      <Route path="/termos-privacidade" component={Terms} />
      <Route component={NotFound} />
    </Switch>
  );
}

import React, { useRef, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Lottie from 'react-lottie';
import {
  MdLabel,
  MdReportProblem,
  MdAccountCircle,
  MdEmail,
  MdDescription,
  MdWeb,
} from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';
import axios from 'axios';

import animationData from '../../assets/success.json';

import { Input, Select, TextArea } from '../../components';

import {
  Container,
  Side,
  Main,
  Header,
  Body,
  Footer,
  Logo,
  Text,
  Title,
  Btn,
  Content,
} from './styles';

import illustration from '../../assets/report-abuse.svg';
import logo from '../../assets/easycontent-logo@2x.png';

const options = [
  {
    value: 'Violação de direitos autorais',
    label: 'Violação de direitos autorais',
  },
  {
    value: 'Abuso sexual, nudez ou pornografia',
    label: 'Abuso sexual, nudez ou pornografia',
  },
  {
    value: 'Discurso ou símbolo de ódio',
    label: 'Discurso ou símbolo de ódio',
  },
  { value: 'Material ofensivo', label: 'Material ofensivo' },
  { value: 'Incitação de violência', label: 'Incitação de violência' },
  {
    value: 'Informações privadas sobre mim',
    label: 'Informações privadas sobre mim',
  },
  { value: 'Possuo um mandado', label: 'Possuo um mandado' },
  {
    value: 'Venda ou promoção de drogas',
    label: 'Venda ou promoção de drogas',
  },
  {
    value: 'Auto ferimento, distúrbios alimentares ou promoção do suicídio',
    label: 'Auto ferimento, distúrbios alimentares ou promoção do suicídio',
  },
  { value: 'Outro tipo de solicitação', label: 'Outro tipo de solicitação' },
];

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

function ReportAbuse() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    document.title = 'EasyContent - Reportar Abuso';

    const params = new URLSearchParams(window.location.search);
    const originalTerm = params.get('ot');
    const originalURL = decodeURIComponent(params.get('ou') || '');

    if (!success) {
      formRef.current.setFieldValue('term', originalTerm);
      formRef.current.setFieldValue('url', originalURL);
    }
  }, [success]);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        term: Yup.string().required('O termo é obrigatório'),
        url: Yup.string()
          .url('Insira uma url válida')
          .required('A url é obrigatória'),
        message: Yup.string().required('O tipo de problema é obrigatório'),
        name: Yup.string().required('O nome é obrigatório'),
        email: Yup.string()
          .email('Insira um e-mail válido')
          .required('O e-mail é obrigatório'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const domain = data.url.match(
        /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/
      )[1];

      const params = new URLSearchParams(window.location.search);
      const widget = params.get('wi') || '';

      const finalData = { type: 'critical', domain, widget, ...data };

      setLoading(true);

      await axios({
        url: 'https://graphql.simpleads.com.br',
        method: 'post',
        data: {
          query: `query{
            Reports(domain:"${finalData.domain}",url:"${finalData.url}",widget:"${finalData.widget}", term:"${finalData.term}",name:"${finalData.name},"email:"${finalData.email}",message:"${finalData.message}",aditional:"${finalData.aditional}",type:"${finalData.type}"){
              status
            }
          }`,
        },
      });

      setLoading(false);
      setSuccess(true);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container>
      <Side>
        <Header>
          <Logo>
            <img src={logo} alt="EasyContent" />
          </Logo>
        </Header>
        <Body>
          <Text>
            <h1>Pesquise, conheça e repita</h1>
            <p>Enriqueça a sua leitura na internet a qualquer momento</p>
          </Text>
        </Body>
        <Footer>
          <img src={illustration} alt="ilustração" />
        </Footer>
      </Side>
      {success ? (
        <Main>
          <Content style={{ justifyContent: 'center' }}>
            <Title>Obrigado pelo feedback!</Title>
            <Lottie
              options={defaultOptions}
              style={{ marginTop: '20px' }}
              height={100}
              width={100}
            />
          </Content>
        </Main>
      ) : (
        <Main>
          <Content>
            <Title>Reportar abuso</Title>
            <Body>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Input
                  id="term"
                  name="term"
                  disabled
                  label="termo pesquisado"
                  placeholder="ex.: casa"
                  icon={MdLabel}
                />
                <Input
                  id="url"
                  name="url"
                  disabled
                  label="página"
                  placeholder="ex.: https://www.plenamulher.com.br/editorias/bem_estar/com-temperaturas-baixas-e-preciso-hidratar-a-pele/"
                  icon={MdWeb}
                />
                <Select
                  id="message"
                  name="message"
                  options={options}
                  icon={MdReportProblem}
                  label="tipo de problema"
                  placeholder="problema em questão"
                />
                <Input
                  id="name"
                  name="name"
                  label="seu nome"
                  placeholder="ex.: José Carlos"
                  icon={MdAccountCircle}
                />
                <Input
                  id="email"
                  name="email"
                  label="seu e-mail"
                  placeholder="ex.: josecarlos@email.com"
                  icon={MdEmail}
                />
                <TextArea
                  id="aditional"
                  name="aditional"
                  label="mais informações (opcional)"
                  icon={MdDescription}
                  placeholder="ex.: o problema ocorreu quando..."
                />
                <Btn type="submit">
                  {!loading ? 'Enviar' : `Enviando`}
                  {loading && <FaSpinner className="icon-spin" />}
                </Btn>
              </Form>
            </Body>
          </Content>
        </Main>
      )}
    </Container>
  );
}

export default ReportAbuse;
